<template>
	<div class="login">
		<div class="login1">
			<div class="login2">
				<div style="float: right;">
					<el-button type="text" icon="el-icon-arrow-left" style="color: #333333"
						@click="goBack()">返回</el-button>
				</div>
				<div v-if="type == '/login'" class="loading">
					<div style="margin-top: 60px">
						<p>
							<span style="
                  margin-right: 30%;
                  margin-left: 10%;
                  font-size: 24px;
                  color: #333333;
                ">登录账号</span>
							<span style="margin-right: 10px">没有账号?</span><span>
								<el-link type="warning" href="#" @click="routerRegister()">立即注册</el-link>
							</span>
						</p>
					</div>
					<div style="margin-top: -20px">
						<el-divider></el-divider>
					</div>
					<!-- 登录页面 -->
					<div class="loginForm">
						<el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="form">

							<el-form-item label="账号" prop="userName">
								<el-input v-model="form.userName" placeholder="请输入邮箱或手机号"
									@keyup.enter.native="login('form')"></el-input>
							</el-form-item>

							<el-form-item label="密码" required prop="passWord">
								<el-input placeholder="请输入密码" v-model="form.passWord" show-password
									@keyup.enter.native="login('form')"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="login('form')" style="width: 100%">登录</el-button>
								<p style="float: right; font-size: 14px">
									<span>已有帐号，</span>
									<span>
										<el-link type="danger" href="#" @click="routerRetrievePW()">忘记密码？</el-link>
									</span>
								</p>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<!-- 手机号注册页面 -->
				<div v-if="type == '/phoneRg'" class="loading">
					<div style="margin-top: 49px">
						<p>
							<span style="
				  margin-right: 30%;
				  margin-left: 10%;
				  font-size: 24px;
				  color: #333333;
				">注册账号</span>
							<span style="margin-right: 10px">已有账号?</span><span>
								<el-link type="warning" href="#" @click="routerLogin()" style="margin-right: 10px">点此登录
								</el-link>
								<!-- <el-link type="danger" href="#" @click="routerRetrievePW()">忘记密码?</el-link> -->
							</span>
						</p>
					</div>
					<!-- 跳邮箱注册
					<div style="color:#66b1ff;border-top:1px solid #909399;cursor: pointer;" @click="tabEmail">邮箱注册
					</div> -->
					<div class="loginForm">
						<el-form class="register" label-position="top" label-width="80px" :model="form" :rules="rules"
							ref="form">

							<el-form-item label="手机号" required prop="phoneNum">
								<el-input v-model="form.phoneNum" placeholder="请输入手机号"></el-input>
							</el-form-item>
							<el-form-item label="密码" required prop="passWord">
								<el-input placeholder="请输入密码" v-model="form.passWord" show-password></el-input>
							</el-form-item>
							<el-form-item label="确认密码" required prop="againPassWord">
								<el-input placeholder="请再次输入密码" v-model="form.againPassWord" show-password></el-input>
							</el-form-item>
							<el-form-item label="验证码" required prop="code">
								<el-input v-model="form.code" placeholder="请输入验证码">
									<template #suffix>
										<el-button type="text" @click="getCode1('form','REGISTER')"
											:disabled="getCodeDisabled1">
											{{ getCodeText }}
										</el-button>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="warning" @click="registerPhone('form')"
									style="width: 100%">立即注册</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<!-- 邮箱注册页面 -->
				<!-- <div v-if="type == '/register'" class="loading">
					<div style="margin-top: 49px">
						<p>
							<span style="
                  margin-right: 13%;
                  margin-left: 10%;
                  font-size: 24px;
                  color: #333333;
                ">注册账号</span>
							<span style="margin-right: 10px">已有账号?</span><span>
								<el-link type="warning" href="#" @click="routerLogin()" style="margin-right: 10px">点此登录?
								</el-link>
								<el-link type="danger" href="#" @click="routerRetrievePW()">忘记密码?</el-link>
							</span>
						</p>
					</div>
				
					<div style="color:#66b1ff;border-top:1px solid #909399;cursor: pointer;" @click="routerRegister">
						手机号注册</div>
					<div class="loginForm">
						<el-form class="register" label-position="top" label-width="80px" :model="form" :rules="rules"
							ref="form">

							<el-form-item label="邮箱" required prop="userName">
								<el-input v-model="form.userName" placeholder="请输入邮箱"></el-input>
							</el-form-item>
							<el-form-item label="密码" required prop="passWord">
								<el-input placeholder="请输入密码" v-model="form.passWord" show-password></el-input>
							</el-form-item>
							<el-form-item label="确认密码" required prop="againPassWord">
								<el-input placeholder="请再次输入密码" v-model="form.againPassWord" show-password></el-input>
							</el-form-item>
							<el-form-item label="验证码" required prop="code">
								<el-input v-model="form.code" placeholder="请输入验证码">
									<template #suffix>
										<el-button type="text" @click="getCode('form')" :disabled="getCodeDisabled">
											{{ getCodeText }}
										</el-button>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="warning" @click="register('form')" style="width: 100%">立即注册</el-button>
								
							</el-form-item>
						</el-form>
					</div>
				</div> -->

				<!-- 忘记邮箱密码页面 -->
				<!-- <div v-if="type == '/retrievePW'" class="loading">
					<div style="margin-top: 60px">
						<p>
							<span style="
                  margin-right: 5%;
                  margin-left: 10%;
                  font-size: 24px;
                  color: #333333;
                ">找回密码</span>
							<span style="margin-right: 10px">手机邮箱不可用?</span><span>
								<el-link type="warning" href="#" @click="contact()" style="margin-right: 10px">联系客服?
								</el-link>
								<el-link type="danger" href="#" @click="routerLogin()">返回登录</el-link>
							</span>

						</p>
					</div>

					<div style="padding:10px 0px 12px 0px;color:#66b1ff;border-top:1px solid #909399;cursor: pointer;"
						@click="forgetPhone()">手机</div>
					<div class="loginForm">
						<el-form class="forget" label-position="top" label-width="80px" :model="form" :rules="rules"
							ref="form">
							<el-form-item label="邮箱" required prop="userName">
								<el-input v-model="form.userName" placeholder="请输入邮箱"></el-input>
							</el-form-item>
							<el-form-item label="密码" required prop="passWord">
								<el-input placeholder="请输入密码" v-model="form.passWord" show-password></el-input>
							</el-form-item>
							<el-form-item label="确认密码" required prop="againPassWord">
								<el-input placeholder="请再次输入密码" v-model="form.againPassWord" show-password></el-input>
							</el-form-item>
							<el-form-item label="验证码" required prop="code">
								<el-input v-model="form.code" placeholder="请输入验证码">
									<template #suffix>
										<el-button type="text" @click="getCode('form')" :disabled="getCodeDisabled">
											{{ getCodeText }}
										</el-button>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="RetrievePW('form')" style="width: 100%">确认</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div> -->
				<!-- 忘记手机号密码 -->
				<div v-if="type == '/retrievePhonePW'" class="loading">
					<div style="margin-top: 60px">
						<p>
							<span style="
				  margin-right: 5%;
				  margin-left: 10%;
				  font-size: 24px;
				  color: #333333;
				">找回密码</span>
							<span style="margin-right: 10px">账号不可用?</span><span>
								<el-link type="warning" href="#" @click="contact()" style="margin-right: 10px">联系客服?
								</el-link>
								<el-link type="danger" href="#" @click="routerLogin()">返回登录</el-link>
							</span>

						</p>
					</div>
					<div style="margin-top: -20px">
						<el-divider></el-divider>
					</div>
					<!-- <div style="padding:10px 0px 12px 0px;color:#66b1ff;border-top:1px solid #909399;cursor: pointer;"
						@click="forgetEmail">邮箱</div> -->
					<div class="loginForm">
						<el-form class="forget" label-position="top" label-width="80px" :model="form" :rules="rules"
							ref="form">
							<el-form-item label="手机号" required prop="phoneNum">
								<el-input v-model="form.phoneNum" placeholder="请输入手机号"></el-input>
							</el-form-item>
							<el-form-item label="密码" required prop="passWord">
								<el-input placeholder="请输入密码" v-model="form.passWord" show-password></el-input>
							</el-form-item>
							<el-form-item label="确认密码" required prop="againPassWord">
								<el-input placeholder="请再次输入密码" v-model="form.againPassWord" show-password></el-input>
							</el-form-item>
							<el-form-item label="验证码" required prop="code">
								<el-input v-model="form.code" placeholder="请输入验证码">
									<template #suffix>
										<el-button type="text" @click="getCode1('form','RESET')"
											:disabled="getCodeDisabled1">
											{{ getCodeText }}
										</el-button>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="RetrievePhonePW('form')"
									style="width: 100%">确认</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="eiback">

		<p>Copyright © 2010-2023 IAMSET艾慕赛特 版权所有 All Rights Reserved.</p>
		<p>Registered Address: HK: 11/F Rykadan Capital Tower 135-137 HOI Bun Road, Kwun Tong KL</p>
	</div>
</template>
<script>
	import $http from "@/request/http";
	const TIME_COUNT = 120;
	export default {
		data() {
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.form.passWord) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			var userName2 = (rule, value, callback) => {
				const phoneReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
				const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;

				if (!value) {
					return callback(new Error('请输入账号'))
				}

				setTimeout(() => {
					if (phoneReg.test(value) || emailReg.test(value)) {
						callback()
					} else {
						callback(new Error('请输入正确手机号或邮箱'))
					}
				}, 100)
			};
			var userPhone1 = (rule, value, callback) => {
				const mailReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
				
				if (!value) {
					return callback(new Error('请输入手机号'))
				}
				setTimeout(() => {
					if (mailReg.test(value)) {
						callback()
					} else {
						callback(new Error('请输入正确的手机号'))
					}
				}, 100)
			};
			return {
				timer: null,
				getCodeText: "获取验证码",
				getCodeDisabled: false,
				getCodeDisabled1: false,
				type: "/login",
				form: {
					userName: "",
					code: "",
					passWord: "",
					againPassWord: "",
					phone: "iamset",
					phoneNum: "",
					subject: "iamset",
					business: ''

				},
				//手机号注册表单
				rules: {
					userName: [{
						trigger: "blur",
						validator: userName2,
					}],
					phoneNum: [{
						// required: true,
						trigger: "blur",
						message: "请输入正确的手机号",
						validator: userPhone1,
					}],
					passWord: [{
						required: true,
						trigger: "blur",
						message: "请输入密码"
					}],
					code: [{
						required: true,
						trigger: "blur",
						message: "请输入验证码"
					}],
					againPassWord: [{
						// required: true,
						trigger: "blur",
						// message: "请再次确认密码",
						validator: validatePass2,
					}, ],
				},
				codeUserName: ''
			};
		},
		methods: {

			//获取邮箱验证码
			getCode() {
				let _this = this;
				// const mailReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
				if (this.form.userName == "") {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "请先输入邮箱！",
					});
					return false
				}

				if (this.form.passWord.length < 4) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "密码长度必须大于三位数！",
					});
					return false;
				}
				if (this.form.passWord != this.form.againPassWord) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "两次密码输入不一致！",
					});
					return false;
				}
				$http.post('sendVerificationCode', this.form, "loading")
					.then(function(res) {
						if (res.code == 200) {
							_this.$message.success({
								showClose: true,
								duration: 3000,
								message: "发送成功，请注意查收",
							});

							//确定注册发送为一个
							_this.codeUserName = _this.form.userName

							if (!_this.timer) {
								_this.getCodeText = TIME_COUNT;
								_this.getCodeDisabled = true;
								_this.timer = setInterval(() => {
									if (_this.getCodeText > 0 && _this.getCodeText <= TIME_COUNT) {
										_this.getCodeText--;
									} else {
										_this.getCodeDisabled = false;
										clearInterval(_this.timer);
										_this.getCodeText = "获取验证码";
										_this.timer = null;
									}
								}, 1000);
							}
						}
					})
					.catch(function() {});
			},
			//获取手机号验证码
			getCode1(form, businessSource) {

				this.form.business = businessSource
				let _this = this;
				if (this.form.phoneNum == '') {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "手机格式不正确！",
					});
					return false
				}
				if (this.form.passWord.length < 4) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "密码长度必须大于三位数！",
					});
					return false;
				}
				if (this.form.passWord != this.form.againPassWord) {
					this.$message.error({
						showClose: true,
						duration: 1500,
						message: "两次密码输入不一致！",
					});
					return false;
				}
				$http.post('sendVerificationCodeByPhone', this.form, "loading")
					.then(function(res) {
						if (res.code == 200) {
							_this.$message.success({
								showClose: true,
								duration: 3000,
								message: "发送成功，请注意查收",
							});

							//确定注册发送为一个
							_this.codeUserName = _this.form.phoneNum

							if (!_this.timer) {
								_this.getCodeText = TIME_COUNT;
								_this.getCodeDisabled = true;
								_this.timer = setInterval(() => {
									if (_this.getCodeText > 0 && _this.getCodeText <= TIME_COUNT) {
										_this.getCodeText--;
									} else {
										_this.getCodeDisabled = false;
										clearInterval(_this.timer);
										_this.getCodeText = "获取验证码";
										_this.timer = null;
									}
								}, 1000);
							}
						}
					})
					.catch(function() {});
			},
			//立即登录callback : （）＝＞｛｝ callback : function(){}
			login(form) {
				let _this = this;
				this.$refs[form].validate((valid) => {
					if (valid) {

						$http.post("login", this.form, "loading")
							.then(function(res) {
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											// localStorage.setItem('satoken',res.data.tokenInfo.tokenValue)
											// localStorage.setItem('userInfo',JSON.stringify(res.data.admin))
											_this.$store.commit('setUserInfo', res.data.admin)
											_this.$store.commit('setTokenInfo', res.data.tokenInfo)
											_this.$store.commit('setIsLogin', "true")
											_this.$router.go(-1)
											// _this.$router.push("/index");
											_this.$emit("header", true);
											_this.$emit("Footer", true);
										},
									});
								}
							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},

			//跳转注册
			routerRegister() {
				this.type = "/phoneRg";
				this.form.userName = "";
				this.form.phoneNum = "";
				this.form.code = "";
				this.form.passWord = "";
				this.form.againPassWord = "";
				console.log(this.form)
			},
			//跳转登录
			routerLogin() {
				this.type = "/login";
				this.form.userName = "";
				this.form.code = "";
				this.form.phoneNum = "";
				this.form.passWord = "";
				this.form.againPassWord = "";
			},

			//立即注册
			register(form) {
				let _this = this;

				//两个不相等
				// if (this.form.userName != this.codeUserName) {
				// 	this.$message.error({
				// 		showClose: true,
				// 		duration: 1500,
				// 		message: "请保证该账号和发送的账号一致！",
				// 	});
				// 	return
				// }

				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("register", this.form, "loading")
							.then(function(res) {
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											_this.type = "/login";
											_this.form.userName = "";
											_this.form.userPhone = "",
												_this.form.code = "";
											_this.form.passWord = "";
											_this.form.againPassWord = "";
										},
									});
								}
							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						_this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},

			//跳转忘记密码
			routerRetrievePW() {
				this.type = "/retrievePhonePW";
				this.form.userName = "";
				this.form.code = "";
				this.form.phoneNum = "";
				this.form.passWord = "";
				this.form.againPassWord = "";
			},

			//跳转邮箱注册
			tabEmail() {
				this.type = "/register";
				this.form.userName = "";
				this.form.phoneNum = "";
				this.form.code = "";
				this.form.passWord = "";
				this.form.againPassWord = ""

			},
			//跳转忘记邮箱
			forgetEmail() {
				this.type = "/retrievePW";
			},
			//跳转忘记手机
			forgetPhone() {
				this.type = "/retrievePhonePW";
			},

			//重置密码
			RetrievePW(form) {
				this.form.business = 'RESET'
				let _this = this;

				this.$refs[form].validate((valid) => {
					if (valid) {

						$http.post("resetPassword", this.form, "loading")
							.then(function(res) {
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											_this.type = "/login";
											_this.form.userName = "";
											_this.form.phoneNum = "";
											_this.form.code = "";
											_this.form.passWord = "";
											_this.form.againPassWord = "";
										},
									});
								}
							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},

			RetrievePhonePW(form) {
				this.form.business = 'RESET'
				let _this = this;
				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("resetPasswordByPhone", this.form, "loading")
							.then(function(res) {
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											_this.type = "/login";
											_this.form.userName = "";
											_this.form.phoneNum = "";
											_this.form.code = "";
											_this.form.passWord = "";
											_this.form.againPassWord = "";
										},
									});
								}
							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},
			//手机号注册
			registerPhone(form) {
				let _this = this;


				this.$refs[form].validate((valid) => {
					if (valid) {
						$http.post("registerByPhone", this.form, "loading")
							.then(function(res) {
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {

											_this.$store.commit('setUserInfo', res.data.admin)
											_this.$store.commit('setTokenInfo', res.data.tokenInfo)
											_this.$store.commit('setIsLogin', "true")
											_this.$router.push("/index");
											_this.$emit("header", true);
											_this.$emit("Footer", true);

										},
									});
								}
							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						_this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},
			//联系客服
			contact() {
				this.$message.success({
					showClose: true,
					duration: 1500,
					message: "联系客服！",
					type: "success",
				});
			},

			//返回首页
			goBack() {
				this.$router.push("/index");
				this.$emit("header", true);
				this.$emit("Footer", true);
			},
		},
		created() {
			this.$emit("header", false);
			this.$emit("Footer", false);
			this.type = this.$route.path;
			console.log("jjjjjjjjjjjjj", this.type)
		},
	};
</script>
<style lang="scss" scoped>
	.login {
		height: 781px;
		background-image: url("../assets/images/login.jpg");
		border: 0 none;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.login2 {
		margin-top: 175px;
		margin-left: 50%;
		width: 425px;
		//   background-color: burlywood;
		height: 468px;
		position: absolute;
	}

	.loginForm {
		line-height: 28px;
		width: 300px;
		margin: 0 auto;
	}


	.eiback {
		font-size: 12px;
		color: #767676;
		text-align: center;
		line-height: 6px;
	}

	.register .el-form-item {
		height: 40px;
		padding-top: 17px;
	}

	::v-deep .register .el-form-item .el-input__inner {
		height: 38px;
	}

	.forget .el-form-item {
		margin-bottom: 20px;
	}
</style>
<style>
	.el-form-item__label {
		line-height: 7px;
	}
</style>